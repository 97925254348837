// extracted by mini-css-extract-plugin
export var recentArticles = "ct_sm";
export var recentArticles__category = "ct_sy";
export var recentArticles__img = "ct_sq";
export var recentArticles__link = "ct_sp";
export var recentArticles__post = "ct_sn";
export var recentArticles__postClamp = "ct_sx";
export var recentArticles__postDate = "ct_ss";
export var recentArticles__postDescriprion = "ct_sw";
export var recentArticles__postInfo = "ct_sr";
export var recentArticles__postStats = "ct_st";
export var recentArticles__postTitle = "ct_sv";
export var recentArticles__title = "ct_sz";