import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'
import kebabCase from 'lodash/kebabCase'

import Breadcrumbs from '~/components/breadcrumbs/Breadcrumbs'
import { BlogArticlePageQueryResult } from '~/templates/blog/Article'
import { ArticleIdProps } from '~/types/common-props'
import { BlogPageContext } from '~/types/page-contex'
import { convertInDateWithoutTime, fileToImageLikeData } from '~/utils'
import ArticleInfo from '~/views/Blog/components/ArticleInfo'

import * as containerStyles from './ArticlePrimeSection.module.scss'

type ArticleHeroProps = ArticleIdProps &
  Pick<BlogPageContext, 'views'> & {
    data: BlogArticlePageQueryResult
    title: string | null
    url: string | null
    category: any
    stats: number
    authors: Queries.STRAPI_AUTHOR[]
  }

const ArticlePrimeSection = ({
  data,
  title,
  authors,
  category,
  url,
  articleId,
  stats,
  views,
}: ArticleHeroProps) => {
  const modifiedDate = convertInDateWithoutTime(
    data.strapiArticle!.modified_date as string,
  )

  return (
    <div className={containerStyles.articlePrime}>
      <Breadcrumbs
        secondLevel="Blog"
        secondLevelPath="/blog/"
        thirdLevel={category?.name || ''}
        thirdLevelPath={`/blog/categories/${kebabCase(category?.name || '')}/`}
        current={title || ''}
        currentPath={`/blog/${url}/`}
      />

      <h1 className={containerStyles.articlePrime__title}>{title}</h1>

      <ArticleInfo
        key={articleId}
        modifiedDate={modifiedDate}
        stats={stats}
        views={views}
        articleId={articleId}
        isArticle
      />

      <div className={containerStyles.articlePrime__authorWrapper}>
        {authors.map(({ id, slug, avatar, name, position }) => (
          <Link
            key={id}
            to={`/blog/author/${kebabCase(slug || '')}/`}
            className={containerStyles.articlePrime__authorLink}
          >
            <div className={containerStyles.articlePrime__authorPhoto}>
              <GatsbyImage
                image={
                  getImage(
                    fileToImageLikeData(avatar!.localFile),
                  ) as IGatsbyImageData
                }
                alt={`${name} ${position} | Codica`}
                title={`${name} ${position}`}
                loading="lazy"
              />
            </div>
            <div>
              <div className={containerStyles.articlePrime__authorName}>
                {name}
              </div>
              <div className={containerStyles.articlePrime__authorPosition}>
                {position}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default ArticlePrimeSection
